<template>
  <v-dialog
    scrollable
    v-model="show"
    fullscreen
    persistent
    content-class="width-fix-dialog"
    v-if="item !== null"
    no-click-animation
  >
    <v-card class="content-card rounded-0">
      <div class="title-bar">
        <div class="title-info">
          <v-chip
            label
            color="primary">
            결제
          </v-chip>
          <v-menu
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-chip
                v-if="item.status === 0"
                class="ml-2"
                label
                v-bind="attrs"
                v-on="on"
              >
                미승인
              </v-chip>
              <v-chip
                v-else-if="item.status === 1 && item.type === 0"
                class="ml-2"
                label
                outlined
                v-bind="attrs"
                v-on="on"
                color="info"
              >
                승인요청
              </v-chip>
            </template>
          </v-menu>
          <p class="sales-number">
            번호: {{ item.no }}
          </p>
        </div>
        <div class="item-wrap d-flex">
          <v-btn
            icon
            @click="init"
            color="black"
            large
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- 매출인 경우 시작 -->
      <div
        class="content-wrap"
        v-if="item.type === 0"
      >
      <!-- 급여대상 시작 -->
        <div class="content-row">
          <p class="content-tit mr-4">
            급여대상
          </p>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >영업담당자</v-chip>
            <span class="item-span mr-1">
              {{`${item.managerName}(${item.manager})`}}
            </span>
          </div>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >영업그룹</v-chip>
            <span class="item-span mr-1">
              {{
                `${comm.getGroupFullPath(groups, item.managerGroupNo)}`
              }}
            </span>
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="getHistories('sales.update', 'group', '영업그룹 변경이력')"
                >
                  <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                </v-btn>
              </template>
              <span>
                영업그룹 변경이력
              </span>
            </v-tooltip>
          </div>
        </div>
        <!-- 급여대상 끝 -->

        <!-- 급여정보 시작 -->
        <div class="content-row align-start">
          <p class="content-tit mr-4">
            급여정보
          </p>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >제목</v-chip>
            <span class="item-span mr-1">
              {{item.title}}
            </span>
          </div>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >인센티브비율</v-chip>
            <span class="item-span mr-1">
              {{item.percentage}}%
            </span>
          </div>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >추가지급요소</v-chip>
            <span class="item-span mr-1">
              {{item.custom == '{}' ? '없음' : item.custom}}
            </span>
          </div>
          <div class="text-item mr-4">
            <v-chip
              outlined
              label
              class="pl-1 pr-1 mr-1"
              small
            >지급합계</v-chip>
            <span class="item-span mr-1"
              :class="item.totalAmount < 0 ? 'error--text' : ''">
                {{ item.totalAmount > 0
                ? comm.priceToString(item.totalAmount)
                : `-${comm.priceToString(item.totalAmount)}`
                }}
              </span>
          </div>
        </div>
        <!-- 급여정보 끝 -->

        <!-- 급여상세 시작 -->
        <div class="content-row align-start">
          <p class="content-tit mr-4">
            급여상세
          </p>
          <div class="item-wrap fix-width">
            <div
              class="item-row total"
            >
              <div>
                <v-chip
                  outlined
                  label
                  class="pl-1 pr-1"
                  small
                >매출기간집계</v-chip>
              </div>
              <span class="total-data">
                {{`${time.moment().startOf('month').format('YYYY-MM-DD')}
                ~ ${time.moment().endOf('month').format('YYYY-MM-DD')}`}}
              </span>
            </div>
            <div class="total my-2 mb-5">
              <v-chip
                outlined
                label
                class="pl-1 pr-1"
                small
              >매출정보</v-chip>
              <div class="total-data text-right"
                v-for="(item, idx) in item.salesInfo"
                :key="`productItems-${idx}`">
                <v-chip
                  x-small
                  label
                  color="error"
                  >
                  고객명
                </v-chip>
                <span style="font-size: 0.9em" class="mx-2">
                  {{item.customerName}}
                </span>
                <v-chip
                  x-small
                  label
                  color="info"
                  >
                  리딩
                </v-chip>
                <span style="font-size: 0.9em" class="mx-2">{{item.productName}}</span>
                <v-chip
                  x-small
                  label
                  color="success"
                  >
                  매출
                </v-chip>
                <span style="font-size: 0.9em" class="mx-2">
                  {{comm.priceToString(item.amount)}}
                </span>
                  <v-chip
                  x-small
                  label
                  color="warning"
                  >
                  일자
                </v-chip>
                <span style="font-size: 0.9em" class="ml-2">
                  {{time.moment(item.created).format('YYYY-MM-DD')}}
                </span>
              </div>
            </div>
            <div class="item-row total">
              <v-chip
                outlined
                label
                class="pl-1 pr-1"
                small
              >매출총액</v-chip>
              <span
                class="total-data"
              >
                <!-- {{ comm.priceToString(
                  setTotalAmount.amount
                ) }} -->
                {{comm.priceToString(setTotalAmount)}}
              </span>
            </div>
            <div class="item-row total">
              <v-chip
                outlined
                label
                class="pl-1 pr-1"
                small
              >환불총액</v-chip>
              <span
                class="total-data error--text">
                {{
                  setTotalAmount > 0
                  ? comm.priceToString(0)
                  : comm.priceToString(setTotalAmount)
                }}
              </span>
            </div>
            <div class="item-row total">
              <v-chip
                outlined
                label
                class="pl-1 pr-1"
                small
              >인센티브</v-chip>
              <span
                class="total-data">
                X {{
                 item.percentage * 0.01
                }}
              </span>
            </div>
            <div class="item-row total border">
              <v-chip
                outlined
                label
                class="pl-1 pr-1"
                small
              >
              지급합계
              </v-chip>
              <span class="total-data bold"
              :class="setTotalAmount < 0 ? 'error--text' : ''">
                {{ setTotalAmount > 0
                ? comm.priceToString(setTotalAmount * item.percentage * 0.01)
                : `-${comm.priceToString(setTotalAmount * item.percentage * 0.01)}`
                }}
              </span>
            </div>
          </div>
        </div>
        <!-- 급여상세 끝 -->
      </div>
      <!-- 댓글 시작-->
      <div class="bottom-wrap">
        <div class="memo-wrap">
          <p class="content-tit">
            <v-icon
              color="primary"
              class="mr-1 mt-1"
            >
              mdi-message-processing-outline
            </v-icon>
            댓글
            <v-chip
              v-if="item.memo != undefined"
              :color="item.memo.length > 0?'primary':''"
              x-small
              class="ml-1 pl-2 pr-2"
            >
              {{ item.memo.length }}
            </v-chip>
          </p>
          <div
            class="memo-list"
            v-if="item.memo != undefined && item.memo.length > 0"
          >
            <div
              class="memo"
              v-for="(memo, index) in item.memo"
              :key="index"
            >
              <div class="memo-top">
                <div class="text">
                  <v-chip
                    label
                    small
                    class="pr-2 pl-2"
                  >
                  <!-- {{`${memo.creatorName}(${memo.creator})`}} -->
                    {{
                      `${comm.getGroupFullPath(groups, memo.creatorGroupNo)} ${memo.creatorName}`
                    }}
                  </v-chip>
                  <v-icon
                    color="primary"
                    v-if="memo.receiver !== null"
                  >
                  <!-- {{memo.receiver !== "" ? 'mdi-arrow-right-thin' : ""}} -->
                  mdi-arrow-right-thin
                  </v-icon>
                  <v-chip
                    label
                    color="primary"
                    v-if="memo.receiver !== null"
                    small
                    class="pr-2 pl-2"
                  >
                  <!-- {{`${memo.receiverName}(${memo.receiver})`}} -->
                  {{
                      `${comm.getGroupFullPath(groups, memo.receiverGroupNo)}
                        ${memo.receiverName}`
                    }}
                  </v-chip>
                  <span class="date ml-2">{{ time.makeLocalTime(memo.created) }}</span>
                </div>
                <!-- <v-chip
                  v-if="memo.files.length > 0"
                  color="info"
                  small
                  close
                  close-icon="mdi-arrow-collapse-down"
                  link
                  @click="fileDownload(memo.files[0])"
                  @click:close="fileDownload(memo.files[0])"
                >
                  <span class="chip-max-width">
                    {{ memo.files[0].name }}
                  </span>
                </v-chip> -->
              </div>
              <p class="memo-body">{{ memo.content }}</p>
            </div>
          </div>
          <div
            class="memo-write-wrap"
          >
            <div class="left-wrap">
              <div class="receiver-file-wrap">
                <v-chip
                  small
                  class="mr-1"
                  v-if="memoReceiver === null"
                >수신인 없음</v-chip>
                <v-chip
                  small
                  class="mr-1"
                  v-else
                  color="warning"
                  @click="memoReceiver = null"
                  link
                  close
                  @click:close="memoReceiver = null"
                >
                  {{ memoReceiver.fullName }}
                </v-chip>
                <!-- <v-chip
                  small
                  class="mr-1"
                  v-if="memoFile === null"
                >첨부파일 없음</v-chip> -->
                <!-- <v-chip
                  small
                  class="mr-1"
                  v-else
                  color="info"
                  @click="memoFile = null"
                  @click:close="memoFile = null"
                  link
                  close
                >
                  {{ memoFile.name }}
                </v-chip> -->
              </div>
              <v-textarea
                solo
                hide-details
                no-resize
                class="area"
                style="font-size:0.9rem"
                flat
                v-model="memoContent"
                value="댓글내용을 입력해주세요."
                rows="3"
              />
            </div>
            <div class="btn-wrap">
              <v-card
                class="staffs-picker"
                v-click-outside="pickerInit"
                v-if="receiverPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="staffsText"
                  placeholder="수신인 검색"
                  @input="staffsFiltering"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="staffsFilter.length > 0">
                  <v-list-item
                    v-for="(row, i) in staffsFilter"
                    :key="i"
                    dense
                    nav
                    link
                    @click="setReceiver(row)"
                  >
                    <v-list-item-title>
                      <v-chip
                        small
                        class="pr-1 pl-1 mr-1"
                        link
                        outlined
                        label
                      >
                        {{ row.group }}
                      </v-chip>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="staffsFilter.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>{{
                      staffsText == null
                      || staffsText.trim().length === 0
                      ?'검색어를 입력해주세요.'
                      :'데이터가 없습니다.'
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="warning"
                    fab
                    small
                    :disabled="receiverPicker === true || memoReceiver !== null"
                    v-on="on"
                    v-bind="attrs"
                    @click="getStaffsList(true, 'receiverPicker')"
                  >
                    <v-icon>mdi-account-multiple-plus</v-icon>
                  </v-btn>
                </template>
                <span>수신인 선택</span>
              </v-tooltip>
              <!-- <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="info"
                    fab
                    small
                    @click="$refs.memofileInput.value=null, $refs.memofileInput.click()"
                    v-on="on"
                    v-bind="attrs"
                    class="mt-1"
                    :disabled="memoFile !== null"
                  >
                    <v-icon>mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>파일첨부</span>
              </v-tooltip> -->
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    depressed
                    class="mt-1"
                    fab
                    small
                    @click="memoRegist"
                    v-on="on"
                    v-bind="attrs"
                    :disabled="memoContent === null || memoContent === ''"
                  >
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
                <span>댓글등록</span>
              </v-tooltip>
            </div>
            <!-- <input
              type="file"
              @change="fileSet($event, 'memoFile')"
              class="file-input"
              ref="memofileInput"
            > -->
          </div>
        </div>
        <div class="visit-wrap">
          <p class="content-tit">
            <v-icon
              color="primary"
              class="mr-1 mt-1"
            >
              mdi-clock-time-two-outline
            </v-icon>
            방문기록
          </p>
          <div
            class="visit"
            :class="item.memo.length > 0 ?'on':''"
            v-if="item.visitors != undefined && item.visitors.length > 0"
          >
            <p
              v-for="(row, index) in item.visitors"
              :key="index"
            >
              {{ row.name }}
              <span>{{ time.makeLocalTime(row.created) }}</span>
            </p>
          </div>
          <div
            class="visit-msg"
            v-else
          >방문기록이 없습니다.</div>
        </div>
      </div>
      <!-- 댓글 끝 -->
    </v-card>
    <dlg-history
      :show.sync="historyDlg.show"
      :data="historyDlg.data"
      :title="historyDlg.title"
    />
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import comm from '@/util/comm';
import time from '@/util/time';
import DlgHistory from '@/components/dialog/DlgHistory.vue';

export default {
  components: {
    DlgHistory,
  },
  name: 'DlgSalaryView',
  props: {
    item: {
      type: Object,
    },
    show: {
      type: Boolean,
      required: true,
    },
    func: {
      type: Function,
    },
  },
  data: () => ({
    time,
    comm,
    priceRule: (value) => {
      const val = Number(value.replaceAll(',', ''));
      if (val < 0 || val > 1000000000 || value === 'NaN') {
        return '0원 이상 10억 미만이어야 합니다.';
      }
      return true;
    },
    numberLengthRule: (value, leng) => {
      if (value === null || Number.isNaN(Number(value)) === true || value.length !== leng) {
        return `${leng}자리의 숫자로만 입력해주세요.`;
      }
      return true;
    },
    installmentRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true || value > 48 || value < 0) {
        return '0 ~ 48 사이의 숫자로 입력해주세요.';
      }
      return true;
    },
    phoneNumberRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true
        || value.length > 11 || value.length < 10) {
        return '10자리 혹은 11자리의 숫자로만 입력해주세요.';
      }
      return true;
    },
    bankAccountRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true
        || value.length > 16 || value.length < 10) {
        return '10 ~ 16자리의 숫자로만 입력해주세요.';
      }
      return true;
    },
    quantityRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true || value > 9999 || value <= 0) {
        return '1 ~ 9999 사이의 숫자로 입력해주세요.';
      }
      return true;
    },
    receiverPicker: false,
    saleFile: null,
    memoContent: null,
    memoFile: null,
    memoReceiver: null,
    groups: [],
    staffsText: null,
    staffs: [],
    staffsFilter: [],
    groupText: null,
    groupFilter: [],
    groupList: [],
    groupPicker: false,
    historyDlg: {
      show: false,
      data: [],
      title: '',
      sub: null,
    },
    confirmDlg: {
      show: false,
      toStatus: 0,
    },
    productItems: null,
    confirmDate: time.moment().format('YYYY-MM-DD'),
    serviceStartDate: time.moment().format('YYYY-MM-DD'),
    serviceEndDate: time.moment().format('YYYY-MM-DD'),
    makeHistories: {
      status: (data, vue) => {
        const result = [];
        const thisType = vue.item.type;
        const msgObj = {
          0: '미승인',
          1: '승인',
          2: '가결제',
          3: thisType === 1 ? '환불일 변경' : '매출일 변경',
        };
        data.forEach((r) => {
          if (r.after.status !== undefined || r.after.date !== undefined) {
            const msgKey = r.after.status !== undefined ? r.after.status : 3;
            let msg = msgObj[String(msgKey)];
            if (r.after.date !== undefined) {
              msg += `[${time.makeLocalDate(r.after.date, 'date')}]`;
            }
            result.push({ ...r, after: msg });
          }
        });
        return result;
      },
      manager: (data, staffs) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.manager !== undefined) {
            const { fullName } = staffs.find((row) => r.after.manager === row.id);
            result.push({ ...r, after: `${fullName}` });
          }
        });
        return result;
      },
      group: (data, vue) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.groupNo !== undefined) {
            result.push({ ...r, after: `${comm.getGroupFullPath(vue.groups, r.after.groupNo)}` });
          } else if (r.after.group !== undefined) {
            result.push({ ...r, after: `${comm.getGroupFullPath(vue.groups, r.after.group)}` });
          }
        });
        return result;
      },
    },
    estDate: time.moment().format('YYYY-MM-DD'),
    estDatePicker: false,
    currentRooms: null,
  }),
  computed: {
    getItemData: () => this.item,
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
    setTotalAmount() {
      if (this.item.salesInfo.length > 0) {
        const amount = this.item.salesInfo?.map((v) => v.amount);
        const val = amount.reduce((acc, curVal) => acc ?? Number(0) + Number(curVal));
        if (val < 0) {
          return `-${val}`;
        }
        return Number(val);
      } return 0;
    },
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.$emit('update:show', false);
      this.memoContent = null;
      this.memoReceiver = null;
      this.memoFile = null;
      this.estDatePicker = false;
      this.estDate = time.moment().format('YYYY-MM-DD');
      this.pickerInit();
    },
    staffsFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.staffs.filter((r) => r.fullName.indexOf(e) !== -1);
        this.staffsFilter = result;
      } else {
        this.staffsFilter = [];
      }
    },
    groupFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.groupList.filter((r) => r.name.indexOf(e) !== -1);
        this.groupFilter = result;
      } else {
        this.groupFilter = [];
      }
    },
    getStaffsList(isPicker = false, target = null) {
      this.staffs = [];
      this.staffsFilter = [];
      this.staffsText = null;
      if (isPicker === true) this.progress(true);
      this.$socket.emit('users.staffs.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          resp.items.forEach((r) => {
            const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
            const user = {
              id: r.id,
              fullName: `${groupName}${r.name}`,
              name: r.name,
              group: groupName,
            };
            this.staffs.push(user);
            if (isPicker === true) {
              this.progress(false);
              this[target] = true;
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          if (isPicker === true) this.progress(false);
        }
      });
    },
    getGroupList(target = null) {
      this.progress(true);
      this.groupText = null;
      this.groupFilter = [];
      this.groupList = [];
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
          this.groupList = comm.makeGroupList(resp.items.groups);
          this.progress(false);
          if (target !== null) this[target] = true;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    setReceiver(staff = null) {
      this.memoReceiver = staff;
      this.receiverPicker = false;
    },
    updateManager(staff) {
      const func = () => {
        this.updateSales({
          no: this.item.no,
          salesManager: staff.id,
        }, '영업담당자가 변경되었습니다.');
      };
      this.confirm({
        show: true,
        func,
        msg: `영업담당자를 ${staff.fullName}님 으로 변경할까요?<br/><br/>※변경한 영업담당자의 실적으로 집계됩니다.`,
        btnText: '변경',
        color: 'error',
      });
    },
    updateGroup(group) {
      const func = () => {
        this.updateSales({
          no: this.item.no,
          groupNo: group.value,
        }, '영업그룹이 변경되었습니다.');
      };
      this.confirm({
        show: true,
        func,
        msg: `영업그룹을 '${group.name}' 그룹으로 변경할까요?<br/><br/>※변경한 영업그룹의 실적으로 집계됩니다.`,
        btnText: '변경',
        color: 'error',
      });
    },
    updateSales(obj, msg) {
      this.progress(true);
      this.$socket.emit('sales.update', obj, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', msg]);
          this.contentsRefresh();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    memoRegist() {
      this.progress(true);
      const reqObj = {
        no: this.item.no,
        content: this.memoContent,
        tag: this.memoReceiver !== null ? [this.memoReceiver.id] : [],
        files: this.memoFile !== null ? [this.memoFile] : [],
      };
      this.$socket.emit('salary.memo.add', reqObj, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', '댓글이 등록되었습니다.']);
          this.memoContent = null;
          this.memoReceiver = null;
          this.memoFile = null;
          this.contentsRefresh();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    pickerInit() {
      this.receiverPicker = false;
      this.managerPicker = false;
      this.staffsText = null;
      this.staffsFilter = [];
      this.discountUpdateValue = 0;
      this.discountUpdateRadio = -1;
      this.discountUpdatePicker = false;
      this.quantityUpdateValue = 0;
      this.quantityUpdatePicker = false;
      this.confirmDlg = {
        show: false,
        toStatus: 0,
      };
      this.confirmDate = this.time.moment().format('YYYY-MM-DD');
      this.serviceStartDate = this.time.moment().format('YYYY-MM-DD');
      this.serviceEndDate = this.time.moment().format('YYYY-MM-DD');
      this.groupText = null;
      this.groupFilter = [];
      this.groupList = [];
      this.groupPicker = false;
    },
    updateDate() {
      this.confirm({
        show: true,
        msg: '영업담당자 인센티브 정산에 즉시 반영됩니다.<br/><br/>정말 변경할까요?',
        func: () => {
          this.updateSales({
            no: this.item.no,
            date: this.confirmDate,
          }, '변경되었습니다.');
        },
        btnText: '변경',
        color: 'primary',
      });
    },
    contentsRefresh() {
      this.confirm({ show: false });
      this.pickerInit();
      this.progress(false);
    },
    getHistories(action, columnName, title) {
      this.progress(true);
      this.$socket.emit('log.get', {
        action,
        key: String(this.item.no),
      }, (resp) => {
        if (resp.result === 'success') {
          if (columnName === 'manager') {
            const staffs = [];
            this.$socket.emit('users.staffs.list.get', {
              page: 1,
              itemsPerPage: 0,
              sortBy: [],
              sortDesc: [],
              filters: [],
            }, (response) => {
              if (response.result === 'success') {
                response.items.forEach((r) => {
                  const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
                  const user = {
                    id: r.id,
                    fullName: `${groupName}${r.name}`,
                    name: r.name,
                    group: groupName,
                  };
                  staffs.push(user);
                });
                const filterData = this.makeHistories[columnName](resp.items, staffs);
                this.historyDlg.data = filterData;
                this.historyDlg.show = true;
                this.historyDlg.title = title;
                this.progress(false);
              } else {
                this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
                console.error(resp);
                this.progress(false);
              }
            });
          } else {
            const filterData = this.makeHistories[columnName](resp.items, this);
            this.historyDlg.data = filterData;
            this.historyDlg.show = true;
            this.historyDlg.title = title;
            this.progress(false);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    setServiceEndDate() {
      const endDate = time.moment(this.serviceStartDate)
        .add(this.item.productTerm + this.item.productFreeTerm, 'M').format('YYYY-MM-DD');
      this.serviceEndDate = endDate;
    },
    // confirmDlgOpen(toStatus = null) {
    //   const item = { ...this.item };
    //   const func = () => {
    //     if (toStatus === 1) {
    //       this.confirmDate = this.time.moment().format('YYYY-MM-DD');
    //       this.serviceStartDate = this.time.moment().format('YYYY-MM-DD');
    //       this.serviceEndDate = this.time.moment().add(
    //         item.productTerm + item.productFreeTerm, 'M',
    //       ).format('YYYY-MM-DD');
    //     } else {
    //       this.confirmDate = this.time.makeLocalTime(item.date, 'date');
    //     }
    //     this.confirmDlg = {
    //       show: true,
    //       toStatus,
    //     };
    //     this.progress(false);
    //   };
    //   if (item.type === 0) {
    //     func();
    //   } else if (item.type === 1) {
    //     this.progress(true);
    //     this.$socket.emit('sales.get', { no: item.origin }, (resp) => {
    //       if (resp.result === 'success') {
    //         const originStatus = resp.item.status;
    //         if (originStatus !== 1 && toStatus === 1) {
    //           this.confirm({
    //             show: true,
    //             msg: `기존 매출건이 승인되지 않아 처리할 수 없습니다.<br/>(기존매출번호: ${item.origin})`,
    //             color: 'primary',
    //             noneCallback: true,
    //           });
    //           this.progress(false);
    //         } else {
    //           func();
    //         }
    //       } else {
    //         this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
    //         console.error(resp);
    //         this.progress(false);
    //       }
    //     });
    //   } else {
    //     this.alert(['error', '오류가 발생했습니다.']);
    //   }
    // },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.sales.menu.sales.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
    getProductRooms(no) {
      this.currentRooms = null;
      this.$socket.emit('rooms.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [true],
        filters: [{
          condition: 'eq',
          column: 'disabled',
          value: 0,
        }, {
          where: 'and',
          condition: 'eq',
          column: 'productNo',
          value: no,
        }],
      }, (resp) => {
        if (resp.result === 'success') {
          this.currentRooms = resp.items;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val === true) {
          this.getGroupList();
          this.getStaffsList();
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.width-fix-dialog {
  min-width: 1000px;
}
.pay-form-wrap {
  position: relative;
}
.pay-form {
  padding: 10px;
  min-width: 640px;
  max-width: 640px;
  position: absolute;
  right: 0px;
  z-index: 1;
  top: -100px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  .v-list {
    max-height: 100px;
    overflow: auto;
  }
  &.search-picker-wide {
    min-width: 300px;
    max-width: 800px;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    .text-input-wrap {
      width: 200px;
    }
  }
  .pay-input-wrap {
    .text-input-wrap {
      width: 100%;
    }
  }
}
.width-fix-dialog {
  min-width: 1500px;
}
.content-card {
  border-radius: 0;
  .sales-number {
    margin: 0;
    padding-left: 10px;
    font-size: 0.8rem;
    color: #888;
  }
}
.title-bar {
  padding: 4px 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  .title-info {
    display: flex;
    align-items: center;
    .item-wrap {
      display: flex;
      align-items: center;
      & * {
        flex-shrink: 0;
      }
    }
  }
  .label-wrap {
    display: flex;
    align-items: center;
  }
}

.text-item {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  .item-tit {
    font-size: 0.9rem;
    color: #262626;
    font-weight: bolder;
    margin-right: 5px;
  }
  .item-span {
    font-size: 0.9rem;
    &.date {
      font-size: 0.8rem;
      margin-left: 4px;
      margin-bottom: -2px;
      color: #888;
    }
  }
}
.pay-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  .confirm-chip {
    min-width: 60px;
    display: flex;
    justify-content: center;
  }
  .pay-info {
    display: flex;
    align-items: center;
    .pay-text {
      color: #262626;
      font-size: 0.9rem;
    }
  }
  & + .pay-row {
    border-top: 1px solid #eee;
    padding-top: 10px;
  }
}
.fix-width {
  min-width: 950px;
}
.item-row {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  & * {
    flex-shrink: 0;
  }
  &.border {
    padding-top: 8px;
    border-top: 1px dashed #ccc;
  }
  &.total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total-data {
      &.bold {
        font-weight: bold;
        font-size: 1rem;
      }
      font-size: 0.9rem;
    }
  }
  & + .item-row {
    margin-top: 10px;
  }
}
.content-tit {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.bottom-wrap {
  display: flex;
  padding: 20px;
  padding-top: 10px;
  .memo-wrap {
    width: 100%;
    .chip-max-width {
      max-width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .memo-write-wrap {
      background-color: #f2f2f2;
      padding: 10px;
      border-radius: 10px;
      margin-right: 2px;
      display: flex;
      align-items: flex-end;
      .left-wrap {
        width: 100%;
        .receiver-file-wrap {
          padding: 0px 4px;
          margin-bottom: 8px;
          width: 100%;
          display: flex;
          align-items: center;
          .name {
            font-size: 0.9rem;
          }
        }
      }
      .btn-wrap {
        margin-left: 8px;
        width: 50px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: center;
        position: relative;
        .staffs-picker {
          width: 250px;
          max-width: 250px;
          position: absolute;
          left: 50px;
          z-index: 1;
          top: 0px;
          box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
            0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
          &.b-type {
            right: 50px;
            left: unset;
          }
          .v-list {
            max-height: 100px;
            overflow: auto;
          }
        }
      }
      .area {
        width: 100%;
        border: 1px solid #ccc;
        background-color: #fff;
        &:focus {
          outline: none;
        }
      }
    }
    .memo-list {
      height: 150px;
      overflow: auto;
      padding-right: 2px;
      padding: 10px;
      background-color: #f2f2f2;
      border: 1px solid #ccc;
      & + .memo-write-wrap {
        margin-top: 10px;
      }
      .memo {
        background-color: #fff;
        padding: 0px;
        border: 1px solid #ccc;
        border-radius: 10px;
        & + .memo {
          margin-top: 10px;
        }
        .memo-top {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          // border-bottom:1px solid #ccc;
          padding: 6px;
          padding-bottom: 0;
          .text {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            .date {
              font-size: 0.8rem;
              color: #333;
            }
          }
        }
        .memo-body {
          font-size: 0.8rem;
          color: #000;
          padding: 6px;
          margin: 0;
          white-space: pre-wrap;
        }
        .memo-file {
          display: flex;
          justify-content: flex-end;
          padding: 6px;
        }
      }
    }
    & + .visit-wrap {
      margin-left: 15px;
    }
  }
  .visit-wrap {
    flex-shrink: 0;
    .visit-msg {
      padding: 10px;
      background-color: #f2f2f2;
      font-size: 0.9rem;
      border-radius: 10px;
      min-width: 350px;
    }
    .visit {
      min-width: 350px;
      background-color: #f2f2f2;
      padding: 10px;
      border-radius: 0px;
      height: 148px;
      overflow: auto;
      border: 1px solid #ccc;
      &.on {
        height: 308px;
      }
      p {
        margin: 0px;
        width: 100%;
        justify-content: space-between;
        font-size: 0.8rem;
        display: flex;
        span {
          margin-left: 8px;
        }
        & + p {
          margin-top: 2px;
        }
      }
    }
  }
}
.content-wrap {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-right: 0;
  border-left: 0;
  overflow-x: hidden;
  height: 100%;
  .content-row {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    & + .content-row {
      margin-top: 6px;
      padding-top: 15px;
      border-top: 1px dashed #ccc;
    }
  }
}
.file-input {
  display: none;
}
.options {
  font-size: 0.9rem;
  padding: 0px 20px;
  min-height: 34px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.update-picker-wrap {
  position: relative;
  .staffs-picker {
    width: 250px;
    max-width: 250px;
    position: absolute;
    left: 30px;
    z-index: 1;
    top: 0px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    &.b-type {
      right: 50px;
      left: unset;
    }
    .v-list {
      max-height: 100px;
      overflow: auto;
    }
  }
  .discount-picker {
    padding: 10px;
    position: absolute;
    left: 30px;
    z-index: 1;
    top: 0px;
    max-width: 300px;
    width: 300px;
    & * {
      flex-shrink: 1;
    }
  }
}
.date-picker-top {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 15px;
  .tit {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: #262626;
    display: flex;
    align-items: center;
    i {
      margin-left: 2px;
    }
  }
}
.date-picker-content {
  border-radius: 0;
  .total-span {
    font-size: 0.85rem;
    font-weight: 500;
  }
}
.confirm-picker-body {
  background-color: #fff;
  padding: 10px;
  padding-top: 0px;
  .section {
    position: relative;
    padding: 15px;
    display: flex;
    align-items: center;
    color: #333;
    justify-content: space-between;
    & + .section,
    & + .section-wrap {
      border-top: 1px dashed #ccc;
    }
    .tit {
      font-size: 0.9rem;
      font-weight: 500;
      margin: 0;
    }
    .sub {
      font-weight: 600;
      font-size: 0.9rem;
      display: flex;
      align-items: center;
    }
  }
}
.gray--text {
  color: #888;
}
.item-span {
  font-size: 0.9rem;
}
</style>
